.header-container-light,
.header-container-dark {
  /* align the header to the center of the page */
  position: fixed;
  /*background-color: white;*/
  background-color: var(--dark-theme-off-black);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  z-index: 100;
  height: 60px;
  width: 100%;
  border-bottom: 1px solid var(--divider-line-gray);
}

.header-container-dark {
  border-bottom: none;
  /*background-color: var(--dark-theme-off-black);*/
  background-color: transparent;
}

.header-company-name-light,
.header-company-name-dark {
  font-family: "Inter";
  font-size: 24px;
  font-weight: 800;
  color: white;
}

.header-company-name-light {
  color: var(--message-blue);
}

.advisor-x-logo-light,
.advisor-x-logo-dark {
  width: 150px;
}

.advisor-x-logo-dark {
  color: #181818;
}

#header-logo {
  width: 200px;
}

.header-name-logo-col {
  display: flex;
  flex: 1;
  justify-content: flex-start;
  align-items: center;
  padding-left: 40px;
}

.header-name-col {
  display: flex;
  flex-direction: row;
  flex: 1;
  justify-content: center;
  align-items: center;
  padding-right: 20px;
}

.header-page-link-text,
.header-page-link-text-selected {
  font-family: "Inter";
  font-size: 16px;
  font-weight: 600;
  color: var(--ios-default-gray);
  margin-left: 60px;
  cursor: pointer;
}

.header-page-link-text:hover,
.header-page-link-text-selected {
  color: white;
  font-weight: 700;
}


.login-screen-container {
    /*background: linear-gradient(to bottom right, #7B5EB7 0%, #010003 100%);*/
    background-image: linear-gradient(138deg, #105045, #060017 0%, #000a08);
    height: 100vh;
}

.login-credentials-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh; /*calc(100vh - 60px);*/
}

.custom-solutions-contact-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: auto;
    padding-top: 20px;
    padding-bottom: 20px;
}

.custom-solutions-contact-container {
    width: 100%;
    max-width: 600px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.contact-us-form-row {
    flex-direction: row;
    padding: 0;
    width: 100%;
    align-items: center;
    display: flex;
    justify-content: center;
}

.contact-us-form-button-row {
    margin: 20px auto auto;
    width: calc(100% - 20px);
}

.contact-us-form-col {
    display: flex;
    flex: 1 1;
    flex-direction: column;
    padding-left: 10px;
    padding-right: 10px;
    width: 100%;
}

.custom-solutions-contact-us-form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 50px;
    width: 600px;
    /*box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);*/
    /*background-color: #49376F;*/
    /*background-color: #19191c;*/
    background-color: rgba(21,24,30,.2);
    border-radius: 10px;
    padding: 30px 40px;
    padding-bottom: 45px;
    border: 1px solid rgba(81,39,206,.4);
}

.custom-solutions-contact-header {
    font-family: "Inter";
    font-size: 14px;
    font-weight: 400;
    color: #f7f7f7;
    text-align: left;
    margin-bottom: 10px;
}

@media screen and (max-width: 750px) {

    .why-superpowered-ai-header-container {
        padding-left: 20px;
    }

    .custom-solutions-contact-us-form-container {
        width: 100%;
    }

    .why-superpowered-custom-solutions-container {
        padding-left: 20px;
        padding-right: 10px;
        width: 90%;
    }

}

@media screen and (max-width: 600px) {

    .custom-solutions-contact-us-form-container {
        width: 100%;
        padding: 15px 20px;
    }

    .custom-solutions-contact-us-form-row {
        flex-direction: column;
    }

    .contact-us-form-button-row {
        width: 100%;
    }

}

.custom-text-area-section {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-top: 10px;
    margin-bottom: 0px;
}

.custom-text-area-container {
    width: 100%;
}

.custom-text-area {
    /* make the height of input field change when the text takes up the entire width */
    height: unset;
    /*min-height: 80px;*/
    width: 100%;
    padding: 20px 20px;
    box-sizing: border-box;
    font-size: 15px;
    font-family: "Inter";
    border: 0px;
    overflow-y: scroll;
    resize: none;
    background-color: #242424 !important;
    border-radius: 10px;
    color: #fff;
}

/* Hey Jonathan, would you be able to create a couple API keys for me in your Superpowered account?
I'll need them in order for the reference KBs to work. */
@import url("https://fonts.googleapis.com/css?family=Inter:200,300,400,500,600,700,800");

.chatbot-chat-input-container {
  /* Center the child elements */
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  width: 100%;
  padding-bottom: 5px;
  padding-top: 5px;
}

.chatbot-input-light-large,
.chatbot-input-dark-large,
.chatbot-input-light-small,
.chatbot-input-dark-small {
  /* make the height of input field change when the text takes up the entire width */
  height: auto;
  width: 100%;
  margin: 20px;
  box-sizing: border-box;
  font-size: 14px;
  font-family: "Inter";
  border: 0px;
  border-radius: 10px;
  overflow-y: visible;
  resize: none;
  background-color: transparent;
  caret-color: #fff;
  color: #fff;
}

.chatbot-input-light-small,
.chatbot-input-dark-small {
  padding: 14px 20px;
}

.chatbot-input-dark-small,
.chatbot-input-dark-large {
  background-color: var(--dark-theme-card-background);
  color: #e8e8e8;
}

.chatbot-input-row-light,
.chatbot-input-row-dark {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid #282828 !important;
  border-radius: 15px !important;
  box-shadow: 0 0 10px 0px rgba(0, 0, 0, 0.2);
  /* background-color: #fff; */
}

.chatbot-input-row-dark {
  background-color: var(--dark-theme-card-background);
}

.chatbot-input-light-small:focus,
.chatbot-input-dark-small:focus,
.chatbot-input-light-large:focus,
.chatbot-input-dark-large:focus {
  outline: none;
}

.chatbot-chat-button-container {
  /* display the button container  */
  display: flex;
  margin-left: 30px;
}

.chatbot-send-icon-container {
  cursor: pointer;
  padding: 0.5rem !important;
  background-color: #4417c7 !important;
  height: 2.5rem;
  width: 2.5rem;
  border-radius: 50% !important;
  border: 0 !important;
}

.chatbot-send-icon-light,
.chatbot-send-icon-dark {
  color: #8f8f8f;
  font-size: 17px;
  margin-top: 3px;
  /* rotate the icon */
  transform: rotate(45deg);
}

.chatbot-send-icon-dark {
  color: #aeaeae;
}

.chatbot-send-icon-container:hover .send-icon-light {
  color: #383838;
}

.chatbot-send-icon-container:hover .send-icon-dark {
  color: #e8e8e8;
}

.chat-bottom-row-col {
  display: flex;
  flex: 1;
}

.browser-prompts {
  border-radius: 8px;
  background-color: transparent;
  color: #fff;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  box-shadow: none;
  border: 1px solid #282828;

  .icon {
    color: #ffd700;
    font-size: 20px;
  }

  &:hover {
    background-color: #333;
  }
}

.prompt-wrapper {
  padding: 10px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
}

.input-wrapper{
    display: flex;
    width: 100%;
}

.file-upload-section {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1100px;
  margin: auto;
  padding-top: 80px;
  padding-bottom: 80px;
  padding-left: 40px;
  padding-right: 40px;
  height: 100vh;
}

input[type="file"] {
  display: none;
}

.file-upload-section {
  width: 750px;
  padding: 0px 25px;
}

.file-upload-container-light,
.file-upload-container-dark {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* border: 1px solid var(--divider-line-gray); */
  border-radius: 5px;
  background-color: #242424 !important;
  padding-top: 65px;
  padding-bottom: 55px;
  margin-bottom: 12px;
  max-width: 750px;
  margin: auto;
}

.file-upload-container-light-inactive {
  background-color: var(--divider-line-gray);
}

.file-upload-container-dark {
  background-color: var(--dark-theme-card-background);
  border: 1px solid var(--off-black);
}

.drag-and-drop-row {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 50%;
  margin-top: 0px;
  margin-bottom: 0px;
}

.choose-file-text {
  margin-right: 5px;
  cursor: pointer;
  text-align: center;
  line-height: 24px;
}

.custom-file-upload {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.drag-files-text {
  font-size: 18px;
  text-align: center;
}

.add-files-text {
  font-family: "Inter";
  font-size: 14px;
  font-weight: 600;
  color: var(--off-black);
  margin-left: 15px;
}

.supported-file-types-icon {
  width: 14px;
  height: 14px;
  margin-left: 4px;
  color: var(--off-black);
  cursor: pointer;
  margin-top: 0px;
  margin-bottom: -2px;
}

.supported-file-type-text {
  font-family: "Inter";
  font-size: 14px;
  color: var(--off-black);
  margin-top: 10px;
  margin-bottom: 8px;
}

.file-upload-supported-types-text {
  font-family: "Inter";
  font-size: 12px;
  font-weight: 300;
  color: #8f8f8f;
  margin-top: 0px;
}

.alternate-upload-method-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin-top: 0px;
  margin-bottom: 20px;
}

.alternate-upload-method-choice-light,
.alternate-upload-method-choice-dark {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: white;
  border: 1px solid var(--divider-line-gray);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  padding: 5px 30px;
  cursor: pointer;
}

.alternate-upload-method-choice-dark {
  background-color: var(--dark-theme-card-background);
  border: none;
}

.alternate-upload-method-choice-light:hover {
  background-color: var(--off-white);
  border: 1px solid var(--divider-line-gray);
}

.alternate-upload-method-choice-dark:hover {
  background-color: var(--dark-theme-card-background-hover);
  border: none;
}

.alternate-upload-method-text-light,
.alternate-upload-method-text-dark {
  font-family: "Inter";
  font-size: 14px;
  font-weight: 500;
  margin-top: 8px;
  margin-bottom: 8px;
  margin-left: 10px;
  margin-right: 10px;
  color: var(--off-black);
}

.alternate-upload-method-text-dark {
  color: var(--divider-line-gray);
}

.file-upload-enter-url-row {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 30px;
}

.file-upload-enter-url-textarea {
  width: 100%;
  padding: 10px 10px;
  box-sizing: border-box;
  font-size: 14px;
  font-family: "Inter";
  border: 0px;
  border-radius: 5px;
  overflow-y: hidden;
  resize: none;
  overflow-x: visible;
  background-color: #fff;
  border: 1px solid #aeaeae;
  white-space: nowrap;
}

.file-upload-enter-url-textarea::-webkit-scrollbar {
  display: none;
}

.file-upload-enter-url-buttons-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

.file-upload-enter-url-button-col {
  width: 125px;
  margin-left: 20px;
}

.chosen-files-header-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 10px;
}

.chosen-files-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-height: 380px;
  overflow-y: auto;
}

/* Hide the scroll bar */

/* Used when the url input field is shown */
.chosen-files-container-shortened {
  max-height: 316px;
}

.file-upload-button-container {
  display: flex;
  width: 100%;
  margin-top: 25px;
}

.add-more-documents-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 25px;
}

.add-more-documents-text {
  font-family: "Inter";
  font-size: 14px;
  font-weight: 500;
  color: var(--message-blue);
  margin-bottom: 10px;
  cursor: pointer;
}

.add-more-documents-text-disabled {
  display: none;
}

.add-more-documents-text:hover {
  font-weight: 600;
}

.url-input-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 20px;
}

.url-input-col {
  display: flex;
  flex-direction: column;
  flex: 4;
  margin-right: 10px;
}

.url-input-button-col {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.toast-body {
  z-index: 999999;
  left: 50%;
  transform: translate(-50%, -20px);
  width: max-content;
  position: absolute;
  top: 6%;
  transition: opacity 0.5s, visibility 0.5s;
  opacity: 0;
  visibility: hidden;
}

.toast-body.show {
  opacity: 1;
  transform: translate(-50%, 0);
  visibility: visible;
}

.toast-wrapper {
  padding: 6px;
  flex-direction: row;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.error {
  background: #ffeded;
  border: 0.5px solid rgba(28, 69, 51, 0.18);
  box-shadow: 0 22px 30px rgba(52, 42, 75, 0.15);
  border-radius: 8px;
  color: #1c4533;
}

.success {
  background: #e4faee;
  border: 0.5px solid rgba(28, 69, 51, 0.18);
  color: #1c4533;
  box-shadow: 0 22px 30px rgba(52, 42, 75, 0.15);
  border-radius: 8px;
}

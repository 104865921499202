/* FADE ANIMATIONS */
.fade-enter {
  opacity: 0;
  transform: scale(0.9); /* Start the transition with scale down */
}

.fade-enter-active {
  opacity: 1;
  transform: scale(1); /* Scale to normal size */
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.fade-exit {
  opacity: 1;
  transform: scale(1);
}

.fade-exit-active {
  opacity: 0;
  transform: scale(0.9); /* Scale down on exit */
  transition: opacity 0.3s ease, transform 0.3s ease;
}

/* SIDEBAR */

.sidebar__button {
  background: transparent;
  padding: 20px;
  border: 1px solid white;
  border-radius: 4px;
  color: white;
  transition: 0.3s linear;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sidebar__button img {
  padding-left: 10px;
}

.sidebar {
  width: 250px;
  padding: 20px;
  color: white;
  transition: all 0.3s ease;
  max-height: 100%;
  border-right: 1px solid #fff;
  transition: width 0.4s ease-in-out, padding 0.4s ease-in-out; /* Animate width and padding */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.sidebar-closed {
  width: 100px;
}

ul {
  list-style-type: none;
  padding: 0;
}

.sidebar__listItem {
  padding: 20px;
  display: flex;
  height: 20px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  gap: 20px;
}

.sidebar__listItem:hover {
  background-color: #444; /* Adjust color to fit your design */
  cursor: pointer;
  transition: background-color 0.3s ease-in-out; /* Smooth transition for background color */
  border-radius: 4px;
}

.sidebar__listItem--selected {
  background-color: #1b574c !important;
  border-radius: 4px;
  cursor: pointer;
}

.sidebar__listItem span {
  white-space: nowrap;
  transition: opacity 0.3s;
  font-size: 14.4px;
}

.sidebar__icon {
  width: 18px;
}

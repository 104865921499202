.query-section {
    padding-bottom: 60px;
}

.generate-page-section {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100vh;
    /*max-width: 1200px;*/
    
}

.long-form-page-main-container {
    display: flex;
    flex-direction: row;
    width: calc(100% - 80px);
    margin: auto;
    max-width: 1500px;
    padding-top: 0px;
    overflow-y: auto;
    max-height: 100vh;
}

.generate-page-section-banner-shown {
    max-height: calc(100vh - 100px)
}

#generate-main-container {
    display: flex;
    flex-direction: column;
    padding-right: 40px;
    padding-left: 40px;
    padding-top: 30px;
    padding-bottom: 80px;
    padding-top: 80px;
    overflow-y: auto;
    flex: 3;
}

#generate-main-container::-webkit-scrollbar {
    display: none;
}

.generate-threads-container-light, .generate-threads-container-dark {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;
    width: 250px;

    padding-left: 25px;
    padding-top: 100px;
    padding-bottom: 0px;

    border-left: 1px solid var(--divider-line-gray);
    height: 100vh;
    overflow: hidden;
}

.generate-threads-container-dark {
    border-left: 1px solid var(--dark-theme-off-black);
}

.generate-threads-container-banner-shown {
    height: calc(100vh - 115px);
}

.chat-sidebar-container-dark {
    border-left: 1px solid var(--dark-theme-off-black);
}

.generate-input-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 800px;
    margin: auto;
    margin-bottom: 50px;
    margin-top: 0px;
}

#generations-list-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 30px;
    padding-right: 0px;
    overflow-y: auto;
}

.query-header-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0px;
    max-width: 740px;
}

.query-knowledge-bases-selected-container {
    display: flex;
    flex-direction: column;
    margin-top: 0px;
    justify-content: flex-start;
    position: relative;
}

.generate-page-clickable-text-light,
.generate-page-clickable-text-dark {
    font-family: "Inter";
    font-weight: 600;
    font-size: 14px;
    color: #fff;
    cursor: pointer;
    width: fit-content;
}

.generate-page-clickable-text-light:hover {
    color: var(--darker-blue);
}

.generate-page-clickable-text-dark {
    color: var(--dark-theme-clickable-text-blue);
}

.generate-page-clickable-text-dark:hover {
    color: var(--dark-theme-clickable-text-blue-hover);
}


#generation-title-text {
    font-size: 24px;
    margin-top: 0px;
}

.export-icon {
    color: var(--off-white);
}

.auto-query-message-text {
    width: 225px;
}


#knowledge-base-container {
    width: 200px;
}

.generate-input-row {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 40px;
    /* Sum of the dropdown widths, plus the margin between */
    /*max-width: 735px;*/
}

.query-text-input-container {
    width: 100%;
    margin-bottom: 15px;
}

.view-query-code-text {
    font-family: "Inter";
    font-weight: 600;
    font-size: 14px;
    color: var(--message-blue);
    cursor: pointer;
}

.view-query-code-text:hover {
    font-weight: 700;
    color: var(--darker-blue);
}

.query-knowledge-bases-selected {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    width: 100%;
}

.selected-knowledge-base-card-container-light,
.selected-knowledge-base-card-container-dark {
    display: block;
    flex-direction: row;
    padding: 4px 16px;
    max-width: 250px;
    background-color: #EEE9F9;
    border-radius: 20px;
    margin: 5px 15px 5px 0px;
    overflow: hidden;
    width: fit-content;
}

.selected-knowledge-base-card-container-dark {
    background-color: #3d3d4a;
}

.selected-knowledge-base-card-title {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.selected-knowledge-base-row {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.selected-knowledge-base-text {
    font-family: "Inter";
    font-weight: 600;
    font-size: 18px;
    line-height: 26px;
    margin-top: 5px;
    margin-bottom: 5px;
    white-space: pre-wrap;
}


.request-data-row {
    display: flex;
    flex-direction: row;
    margin-top: 20px;
}

.request-data-col {
    display: flex;
    flex-direction: column;
    margin-right: 50px;
}


#view-previous-exports-text-light, #view-previous-exports-text-dark {
    color: var(--message-blue);
    font-family: "Inter";
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
    width: 160px;
    
}

#view-previous-exports-text-dark {
    color: var(--dark-theme-clickable-text-blue);
}

#view-previous-exports-text-light:hover {
    color: var(--darker-blue);
}

#view-previous-exports-text-dark:hover {
    color: var(--dark-theme-clickable-text-blue-hover);
}


.generate-divider-row {
    height: 1px;
    width: 100%;
    /*border-bottom: 1px dashed var(--medium-gray);*/
    background-image: linear-gradient(to right, var(--medium-gray) 33%, rgba(255, 255, 255, 0) 0%);
    background-position: bottom;
    background-size: 9px 1px;
    background-repeat: repeat-x;
    margin-top: 40px;
}

.generate-result-container {
    margin: auto;
    margin-top: 0px;
    width: 100%;
    max-width: 900px;
}

.generate-intro-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 20px;
    margin-bottom: 20px;
}

.generate-problem-row {
    margin-top: 20px;
    margin-bottom: 20px;
}

.generate-section-row {
    margin-top: 50px;
}

.generate-sources-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 20px;
}

.generate-section-title-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}


.generate-edit-section-icon {
    display: inline;
    color: var(--medium-gray);
    cursor: pointer;
}

.generate-edit-section-icon:hover {
    color: var(--off-black);
}

.generate-edit-section-button-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    margin-top: 10px
}

.generate-edit-section-cancel-button-col {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    margin-right: 10px;
}

.generate-section-title-light,
.generate-section-title-dark {
    font-family: "Inter";
    font-weight: 600;
    color: var(--medium-gray);
    font-size: 18px;
    margin-top: 5px;
    margin-bottom: 5px;
}

.generate-section-title-dark {
    color: var(--divider-line-gray);
}

.generate-sources-text-light,
.generate-sources-text-dark {
    font-family: "Inter";
    font-weight: 600;
    color: #fff;
    font-size: 14px;
    margin-top: 5px;
    margin-bottom: 5px;
    margin-right: 25px;
}

.generate-sources-text-dark {
    color: var(--divider-line-gray)
}


.previous-exports {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.previous-exports-row {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.export-filename-text {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.previous-exports-name-col {
    display: flex;
    flex: 4;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.previous-exports-type-col, .previous-exports-download-col {
    display: flex;
    flex: 1;
    align-items: center;
}

.previous-exports-download-col {
    justify-content: flex-end;
    align-items: center;
}

.generation-export-link-light, .generation-export-link-dark {
    font-family: "Inter";
    font-weight: 600;
    font-size: 14px;
    color: var(--message-blue);
    text-decoration: none;
    margin: 0px;
}

.generation-export-link-light:hover {
    color: var(--darker-blue);
}



.llm-configuration-modal-container {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 25px;
    padding-right: 25px;
    width: 100%;
}

.llm-configuration-modal-options {
    align-items: flex-start;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-top: 15px;
    width: 100%;
}

.llm-configuration-modal-message-container {
    margin-top: 15px;
    width: 100%;
}

.llm-configuration-modal-dropdown {
    margin-right: 20px;
}

.llm-configuration-modal-button-container {
    margin-top: 20px;
    width: 100%;
}

.compliance-review-section {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    overflow-y: scroll;
}

.compliance-review-container {
    display: flex;
    flex-direction: column;
    width: calc(100% - 100px);
    margin: auto;
    max-width: 1000px;
    padding-bottom: 80px;
    margin-top: 70px;
}

.compliance-review-header-text {
    font-size: 24px;
    font-weight: 600;
    color: #fff;
    margin-bottom: 20px;
}

.compliance-review-use-case-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-bottom: 20px;
}

.use-case-header-text {
    font-size: 14px;
    font-weight: 600;
    color: #fff;
    margin-bottom: 10px;
    width: fit-content;
    text-wrap: nowrap;
    margin-right: 20px;
    width: 80px;
}

.message-for-review-header-text {
    font-size: 16px;
    font-weight: 600;
    color: #fff;
    margin-top: 30px;
    margin-bottom: 10px;
    width: fit-content;
    text-wrap: nowrap;
    margin-right: 20px;

}

.message-for-review-text {
    font-size: 14px;
    font-weight: 400;
    color: #383838;
    margin-bottom: 10px;
    line-height: 20px;
    white-space: pre-wrap;
}

.submit-review-button-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: flex-end;
    margin-top: 40px;
}

.compliance-review-result-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 40px;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: linear-gradient(137.63deg,#105045 -11.29%,#060017 14.54%,#000a08 101.66%);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: "Trenda-Regular";
  font-weight: 400;
  src: url("../public/assets/font/trenda_regular.otf") format("opentype");
}
@font-face {
  font-family: "Trenda-semibold";
  font-weight: 600;
  src: url("../public/assets/font/trenda_semibold.otf") format("opentype");
}
@font-face {
  font-family: "Trenda-Bold";
  font-weight: 700;
  src: url("../public/assets/font/trenda_bold.otf") format("opentype");
}

* {
  font-family: "Trenda-Regular", sans-serif;
}

/* Font weight classes */
.font-weight-bold {
  font-family: "Trenda-Bold", sans-serif !important;
}
.font-weight-normal {
  font-family: "Trenda-Regular", sans-serif !important;
}
.font-weight-semi-bold {
  font-family: "Trenda-semibold", sans-serif !important;
}

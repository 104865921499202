
.file-list-section {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.file-list-header-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-left: 53px;
    padding-right: 25px;
}

.file-list-row-light, .file-list-row-dark {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.1);
    background-color: var(--dark-theme-card-background);
    border-radius: 5px;
    margin-bottom: 6px;
    padding: 5px 10px;
    padding-right: 25px;
}

.file-list-row-dark {
    background-color: var(--dark-theme-card-background);
}

.new-file-list-row-light, .new-file-list-row-dark {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    background-color: white;
    padding-left: 0px;
    margin-bottom: 10px;
    padding-right: 10px;
    border-radius: 5px;
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.1);
}

.new-file-list-header-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-right: 10px;
    padding-bottom: 10px;
}

.new-file-list-row-dark {
    background-color: var(--dark-theme-card-background);
}

.file-list-row-white {
    background-color: white;
}

.new-file-list-name-col {
    display: flex;
    flex: 6;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding-left: 15px;
}

.sort-arrow-row {
    align-items: center;
    cursor: pointer;
    display: flex;
    flex-direction: row;
}

.file-list-id-col {
    display: flex;
    flex: 4;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin: 0px 10px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.file-list-name-col {
    display: flex;
    flex: 4;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin: 0px 10px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.file-list-added-date-col {
    display: flex;
    flex: 3;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.file-list-header-text {
    font-family: "Inter";
    font-weight: 600;
    font-size: 14px;
    cursor: pointer;
    color: var(--medium-gray);
}

.file-list-status-header-col {
    display: flex;
    flex: 1;
    justify-content: flex-start;
}

.file-list-status-header-text {
    font-family: "Inter";
    font-weight: 600;
    font-size: 14px;
    color: var(--medium-gray);
}

.file-list-header-text-selected {
    font-weight: 700;
    color: var(--off-black);
}

.file-list-date-header-text {
    padding-left: 0px;
}

.sort-files-icon {
    cursor: pointer;
    font-size: 18px;
    color: var(--off-black);
    padding-left: 8px;
    margin-top: 4px;
}

.sort-files-icon-selected {
    color: black;
}

.file-list-remove-col, .file-list-select-col {
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
}

.file-list-select-col {
    flex: 3;
    justify-content: flex-start;
}

.file-list-added-date-text {
    font-family: "Inter";
    font-weight: 400;
    font-size: 14px;
    color: var(--off-black);
}

.new-file-delete-icon-light, .new-file-delete-icon-dark {
    cursor: pointer;
    font-size: 18px;
    margin-right: 0px;
    margin-top: 12px;
    margin-bottom: 12px;
    color: var(--off-black)l
}

.new-file-delete-icon-dark {
    color: var(--divider-line-gray);
}

.new-file-success-icon {
    color: #4BAE4F;
    font-size: 22px;
    margin-right: 10px;
    margin-top: 12px;
    margin-bottom: 12px;
}

.new-file-error-icon {
    color: var(--delete-red);
    font-size: 22px;
    margin-right: 10px;
    margin-top: 12px;
    margin-bottom: 12px;
}

.new-file-success-icon {
    color: var(--success-green);
    font-size: 22px;
    margin-right: 10px;
    margin-top: 12px;
    margin-bottom: 12px;
}

.file-list-view-document-col {
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: flex-end;
}

.file-list-status-col {
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: flex-start;
}

.file-list-name-text-light, .file-list-name-text-dark {
    font-family: "Inter";
    font-weight: 400;
    font-size: 14px;
    margin-top: 10px;
    margin-bottom: 10px;
    max-width: 400px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    color: #af98f3;
}

.file-list-name-text-dark {
    color: var(--divider-line-gray);
}

.file-list-view-document-text-light, .file-list-view-document-text-dark {
    font-family: "Inter";
    font-weight: 600;
    font-size: 14px;
    color: var(--message-blue);
    cursor: pointer;
}

.file-list-view-document-text-dark {
    color: var(--dark-theme-clickable-text-blue)
}

.file-list-view-document-text-light:hover {
    font-weight: 700;
    color: var(--darker-blue);
}

.file-list-view-document-text-dark:hover {
    font-weight: 700;
    color: var(--dark-theme-clickable-text-blue-hover);
}

.file-list-view-document-text-disabled-light, .file-list-view-document-text-disabled-dark {
    cursor: default;
}

.file-list-view-document-text-disabled-light:hover {
    cursor: default;
    font-weight: 600;
    color: var(--message-blue);
}

.file-list-view-document-text-disabled-dark:hover {
    cursor: default;
    font-weight: 600;
    color: var(--dark-theme-clickable-text-blue);
}

.file-list-status-text {
    font-family: "Inter";
    font-weight: 400;
    font-size: 14px;
    color: var(--off-black)
}

@keyframes spinner {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.new-file-loading-spinner {
    /* spinner size */
    width: 12px;
    height: 12px;
    border: 3px solid #f3f3f3;
    border-top: 3px solid #2A0084;
    border-radius: 50%;
    animation: spinner 1.5s linear infinite;
    margin-right: 10px;
}

.new-file-spinner-container {
    margin: none;
}


@media screen and (max-width: 930px) {
    .file-list-name-text {
        max-width: 350px;
    }
    .file-list-added-date-col {
        flex: 3;
    }
}

@media screen and (max-width: 850px) {
    .file-list-name-text {
        max-width: 300px;
    }
}
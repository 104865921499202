.knowledge-base-section {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  overflow-y: scroll;
}

.knowledge-page-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.knowledge-bases-container {
  display: flex;
  flex-direction: column;
  width: calc(100% - 100px);
  margin: auto;
  max-width: 1000px;
  padding-bottom: 80px;
  margin-top: 70px;
}

.knowledge-base-text {
  font-family: "Inter";
  color: #383838;
  font-weight: 600;
  font-size: 18px;
  cursor: pointer;
  margin-top: 30px;
  width: fit-content;
}

.knowledge-base-text:hover {
  color: black;
  font-weight: 700;
}

input[type="file"] {
  display: none;
}

.file-upload-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--divider-line-gray);
  border-radius: 5px;
  /* background-color: white; */
  width: 650px;
  padding-top: 65px;
  padding-bottom: 55px;
  margin-bottom: 12px;
}

.file-upload-container-inactive {
  background-color: var(--divider-line-gray);
}

.knowledge-base-list-container {
  margin-top: 20px;
}

#knowledge-coming-soon-text {
  color: var(--medium-gray);
  margin-left: 6px;
  font-weight: 300;
}


.custom-text-input-section {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-top: 0px;
    margin-bottom: 0px;
}

.custom-text-input-container {
    width: 100%;
}

.custom-text-input-light, .custom-text-input-dark {
    width: 100%;
    padding: 15px 15px;
    box-sizing: border-box;
    font-size: 15px;
    font-family: "Inter";
    border: 0px;
    /*overflow-y: hidden;*/
    resize: none;
    background-color: #3E2E5F;
    border: 1px solid var(--divider-line-gray);
    border-radius: 6px;
    font-family: "Inter";
    font-size: 14px;
    font-weight: 400;
    color: var(--off-black);
    /* define the placeholder text color */
}

.custom-text-input-dark {
    /*background-color: var(--dark-theme-card-background);
    border: 1px solid var(--off-black);*/
    color: var(--divider-line-gray);
}

.custom-text-input-light-disabled {
    background-color: var(--divider-line-gray);
}

.custom-text-input-dark-disabled {
    background-color: var(--dark-theme-card-background-hover);
}

.custom-text-input::placeholder {
    color: var(--medium-gray);
}
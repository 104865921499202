.copy-markdown-icon-container {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 0px;
    margin-bottom: -15px;
    margin-right: 6px;
}

.copy-markdown-icon {
    cursor: pointer;
    z-index: 100;
    margin-bottom: -40px;
}

.faq-link {
    color: var(--off-white);
    font-weight: 400;
    cursor: pointer;
    text-decoration: underline;
}
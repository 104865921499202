.advisor-assist-section {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
}

.advisor-assist-container {
    display: flex;
    flex-direction: column;
    width: calc(100% - 100px);
    margin: auto;
    max-width: 1000px;
    padding-bottom: 80px;
    margin-top: 50px;

}

.query-section {
    padding-bottom: 60px;
}

.query-input-container {
    display: flex;
    flex-direction: column;
    max-width: 900px;  
}

.query-header-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0px;
    max-width: 740px;
}

.query-knowledge-bases-selected-container {
    display: flex;
    flex-direction: column;
    margin-top: 0px;
    justify-content: flex-start;
    position: relative;
}

.query-page-clickable-text-light, .query-page-clickable-text-dark {
    font-family: "Inter";
    font-weight: 600;
    font-size: 14px;
    color: var(--message-blue);
    cursor: pointer;
    width: 125px;
}

.query-page-clickable-text-light-disabled, .query-page-clickable-text-dark-disabled {
    font-family: "Inter";
    font-weight: 600;
    font-size: 14px;
    width: 125px;
    color: #8a76b5;
    cursor: default;
}

.auto-query-message-text {
    width: 225px;
}

.summary-system-message-text {
    width: 215px;
}

.query-page-clickable-text-dark {
    color: var(--dark-theme-clickable-text-blue);
}

.query-page-clickable-text-light:hover {
    font-weight: 700;
    color: var(--darker-blue);
}

.query-page-clickable-text-dark:hover {
    font-weight: 700;
    color: var(--dark-theme-clickable-text-blue-hover);
}

.query-options-row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 0px;
    margin-top: 25px;
}

.query-options-header-row {
    margin-bottom: 10px;
    margin-top: 25px;
}

.query-options-col {
    display: flex;
    flex-direction: column;
    margin-right: 45px;
}

.query-options-text-col {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
}

.query-options-toggle-col {
    margin-top: 8px;
}

.query-info-box {
    display: none;
}

#knowledge-base-container {
    width: 200px;
}

.query-input-row {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 40px;
    max-width: 800px;
}

.query-text-input-container {
    width: 100%;
    margin-bottom: 15px;
}

.view-query-code-text {
    font-family: "Inter";
    font-weight: 600;
    font-size: 14px;
    color: var(--message-blue);
    cursor: pointer;
}

.view-query-code-text:hover {
    font-weight: 700;
    color: var(--darker-blue);
}

.query-knowledge-bases-selected {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    width: 100%;
}

.selected-knowledge-base-card-container-light, .selected-knowledge-base-card-container-dark {
    display: block;
    flex-direction: row;
    padding: 4px 16px;
    max-width: 250px;
    background-color: #EEE9F9;
    border-radius: 20px;
    margin: 5px 15px 5px 0px;
    overflow: hidden;
}

.selected-knowledge-base-card-container-dark {
    background-color: #3d3d4a;
}

.selected-knowledge-base-card-title {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.selected-knowledge-base-row {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.selected-knowledge-base-text {
    font-family: "Inter";
    font-weight: 600;
    font-size: 18px;
    line-height: 26px;
    margin-top: 5px;
    margin-bottom: 5px;
    white-space: pre-wrap;
}


.query-searches-section {
    display: flex;
    flex-direction: column;
    margin-top: 50px;
    width: 100%;
}

.query-results-section {
    display: flex;
    flex-direction: column;
    margin-top: 70px;
}

.result-type-text-light, .result-type-text-dark {
    font-family: "Inter";
    font-weight: 600;
    font-size: 16px;
    color: var(--medium-gray);
    margin-right: 100px;
    cursor: pointer;
}

.result-type-text-dark {
    color: var(--light-gray);
}

.result-type-text-light-selected, .result-type-text-dark-selected {
    font-family: "Inter";
    font-weight: 700;
    font-size: 16px;
    color: var(--off-black);
    margin-right: 100px;
    /*text-decoration: underline;*/
    /* make the underline bolder, and have a larger offset
    text-decoration-thickness: 2px;
    text-underline-offset: 5px;*/
}

.result-type-text-dark-selected {
    color: var(--divider-line-gray);
}

.result-type-text-light:hover {
    color: var(--off-black);
}

.result-type-text-dark:hover {
    color: var(--divider-line-gray);
}

.system-message-container {
    width: 100%;
}

.system-message-input-container {
    width: 100%;
    margin-top: 15px;
    margin-bottom: 15px;
}


.query-result-card-container-light, .query-result-card-container-dark {
    display: flex;
    flex-direction: column;
    padding: 20px 25px;
    width: 100%;
    overflow: hidden;
    box-shadow: 0px 0px 6px 0px rgba(0,0,0,0.2);
    border-radius: 5px;
    margin-bottom: 25px;
}

.query-result-card-container-dark {
    box-shadow: 0px 0px 6px 0px rgba(0,0,0,0.8);
}

.query-result-options-row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 10px;
    margin-top: 10px;
}

.query-result-card-header-row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 15px;
    margin-top: 5px;
}

.query-result-title-container-light, .query-result-title-container-dark {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-left: 0px;
    padding: 0px 5px;
    /*cursor: pointer;
    background-color: rgba(93, 36, 216, 0.15);*/
    border-radius: 5px;
}

/*.query-result-title-container-light:hover, .query-result-title-container-dark:hover {
    background-color: rgba(93, 36, 216, 0.3);
}*/

.query-result-title-text-light, .query-result-title-text-dark {
    font-family: "Inter";
    font-weight: 500;
    font-size: 16px;
    color: var(--off-black);
    margin-top: 8px;
    margin-bottom: 8px;
}

.query-result-title-text-dark {
    color: var(--dark-theme-clickable-text-blue);
}

.query-search-card-section {
    display: flex;
    flex-direction: column;
}

.query-search-card-container-light, .query-search-card-container-dark {
    display: flex;
    width: fit-content;
    max-width: 100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    overflow: hidden;
    border: 1px solid var(--divider-line-gray);
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.2);
    border-radius: 5px;
    margin: 5px 20px 5px 0px;
}

.query-search-card-container-dark {
    background-color: var(--dark-theme-card-background);
}



.main-button-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    background-color: #4417c7;
    margin: auto;
    padding: 10px 18px;
    border-radius: 8px;
    cursor: pointer;
}

.main-add-button-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color:#4417c7;
    padding: 10px 18px;
    padding-right: 25px;
    border-radius: 8px;
    cursor: pointer;
}

.main-button-container:hover, .main-add-button-container:hover {
    background-color: #4417c7;
}

.main-button-container-inactive-light, .main-button-container-inactive-light:hover,
.main-add-button-container-inactive-light, .main-add-button-container-inactive-light:hover {
    background-color: #242424;
    cursor: default;
}

.main-button-container-inactive-dark, .main-button-container-inactive-dark:hover,
.main-add-button-container-inactive-dark, .main-add-button-container-inactive-dark:hover {
    background-color: #4c4857;
    cursor: default;
}

.button {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    padding-top: 0px;
    padding-bottom: 0px;
    color: white;
    background-color: transparent;
    border: none;
    cursor: pointer;
}

.button-inactive {
    cursor: default;
}

.button-icon-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    margin-right: 10px;
}

.button-text-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.add-button-icon {
    color: white;
    font-size: 16px;
    margin-top: 2px;
}

.delete-button-container {
    background-color: var(--delete-red);
}

.delete-button-container:hover {
    background-color: #94180f;
}

.cancel-button-container {
    background-color: white;
    border: 1px solid var(--divider-line-gray);
}

.cancel-button-container:hover {
    background-color: var(--divider-line-gray);
}

.cancel-button {
    color: var(--off-black);
}

.view-code-button-container-light, .view-code-button-container-dark {
    display: block;
    background-color: white;
    border: 2px solid var(--divider-line-gray);
    padding: 10px 24px;
    border-radius: 8px;
    cursor: pointer;
}

.view-code-button-container-dark {
    background-color: var(--dark-theme-card-background);
    border: 2px solid var(--dark-theme-background-color);
}

.view-code-button-light {
    color: var(--message-blue)
}

.view-code-button-dark {
    color: var(--dark-theme-clickable-text-blue)
}

.view-code-button-container-light:hover {
    background-color: var(--divider-line-gray);
}

.view-code-button-container-dark:hover {
    background-color: var(--dark-theme-card-background-hover);
}

.info-icon-container {
    position: relative;
}

.info-icon-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
    width: fit-content;
}

.info-icon-light, .info-icon-dark {
    color: var(--medium-gray);
    font-size: 14px;
    margin-top: 0px;
    margin-left: 5px;
}

.info-icon-dark {
    color: var(--light-gray);
}
@import url("https://fonts.googleapis.com/css?family=Inter:200,300,400,500,600,700,800");

* {
  box-sizing: border-box;
}

.chat-main-section-light,
.chat-main-section-dark {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  min-height: 100%;
}

.chat-main-section-dark {
  background-color: var(--dark-theme-background-color);
}

.chat-main-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  flex: 4;
  padding-top: 0px;
  height: 100vh;
}

.chat-messages-container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  width: 100%;
}

/* Hide the scroll bar 
.chat-messages-container::-webkit-scrollbar {
    display: none;
}*/

.chat-send-message-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 1000px;
  padding: 10px 60px 20px 60px;
  border-radius: 15px !important;
}

.chat-sidebar-container-light,
.chat-sidebar-container-dark {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  width: 250px;
  /* flex: 1; */
  border-left: 1px solid var(--divider-line-gray);
  padding-left: 40px;
  padding-top: 30px;
  padding-bottom: 20px;
  /* 100vh minus the 40px of padding here, 45px header height, and 10px of extra padding in the Dashboard Wrapper */
  height: 100vh;
  overflow: hidden;
}

.chat-sidebar-container-dark {
  border-left: 1px solid var(--dark-theme-off-black);
}

.chat-sidebar-container-banner-shown {
  height: calc(100vh - 95px);
}

.chat-message-container-light,
.chat-message-container-dark {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  padding: 25px 40px 25px 40px;
  /* border-bottom: 1px solid var(--divider-line-gray); */
}

.chat-message-container-dark {
  border-bottom: 1px solid var(--dark-theme-off-black);
}

.chat-message-content-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 850px;
  /* 3 to 1 flex ratio, minus the left sidebar width, minus the left and right padding on the message container */
  /*max-width: calc(75vw - 360px);*/
  margin: auto;
  text-align: left;
}

.chat-message-header-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 850px;
  margin: auto;
}

.view-previous-chats-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.view-previous-chats-text-light,
.view-previous-chats-text-dark {
  font-family: "Inter";
  font-weight: 600;
  font-size: 14px;
  color: var(--message-blue);
  margin-right: 10px;
  cursor: pointer;
}

.view-previous-chats-text-light:hover {
  color: var(--darker-blue);
}

.chat-message-copy-icon-container {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.copy-text-icon-light,
.copy-text-icon-dark {
  color: var(--medium-gray);
  cursor: pointer;
  font-size: 20px;
}

.copy-text-icon-dark {
  color: var(--light-gray);
}

.copy-text-icon-light:hover {
  color: #fff;
}

.copy-text-icon-dark:hover {
  color: var(--divider-line-gray);
}

.send-to-review-text,
.send-to-review-text {
  display: none;
  font-family: "Inter";
  font-weight: 500;
  font-size: 12px;
  color: var(--dark-gray);
}

.copy-text-icon-light:hover + .send-to-review-text {
  display: block;
}

.copy-text-icon-dark:hover + .send-to-review-text {
  display: block;
}

.copied-message-text {
  color: var(--off-black);
  font-family: "Inter";
  font-size: 14px;
  font-weight: 600;
}

.ai-chat-message-container-light {
  color: #fff !important;
}

.ai-chat-message-container-dark {
  background-color: var(--dark-theme-background-color);
}

.user-chat-message-container-light {
  /* border-bottom: 1px solid var(--divider-line-gray); */
}

.user-chat-message-container-dark {
  background-color: var(--dark-theme-card-background);
  border-bottom: 1px solid var(--dark-theme-off-black);
}

.chat-message-header-text {
  font-family: "Inter";
  font-weight: 600;
  font-size: 14px;
  color: var(--medium-gray);
  margin-top: 5px;
  margin-bottom: 5px;
}

.chat-message-text-light,
.chat-message-text-dark {
  font-family: "Inter";
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* color: var(--off-black); */
  color: #fff;
  margin-top: 0px;
  margin-bottom: 0px;
  overflow-x: hidden;
  width: 100%;
}

.chat-message-text-light h1,
.chat-message-text-dark h1 {
  font-size: 32px;
  line-height: 44px;
}

.chat-message-text-light h2,
.chat-message-text-dark h2 {
  font-size: 26px;
  line-height: 36px;
}

.chat-message-text-light h3,
.chat-message-text-dark h3 {
  font-size: 22px;
  line-height: 32px;
}

.chat-message-text-dark {
  color: var(--divider-line-gray);
}

.new-chat-thread-button-container {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-end;
  width: 100%;
}

.all-chat-threads-container {
  display: flex;
  flex: 10;
  flex-direction: column;
  width: 100%;
  margin: 30px 0px;
  padding-right: 0px;
  overflow-y: auto;
}

.chat-thread-container-light,
.chat-thread-container-dark {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0px 15px;
  padding-left: 0px;
  /* border: 1px solid var(--divider-line-gray); */
  border: 1px solid #282828;
  border-radius: 5px;
  margin-top: 6px;
}

.chat-thread-container-dark {
  border: 1px solid var(--off-black);
}

.chat-thread-title-col {
  display: flex;
  flex-direction: row;
  flex: 8;
  width: 100%;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-top: 6px;
  padding-bottom: 6px;
  padding-left: 15px;
}

.chat-thread-title-col-disabled {
  cursor: default;
}

.chat-thread-title-text {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.delete-chat-thread-col {
  padding: 0px 0px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  flex: 1;
}

.delete-chat-thread-col-disabled {
  cursor: default;
}

.delete-chat-thread-icon-light {
  display: none;
  color: var(--medium-gray);
}

.delete-chat-thread-icon-dark {
  display: none;
  color: var(--light-gray);
}

.chat-thread-container-light:hover .delete-chat-thread-icon-light {
  display: block;
}

.chat-thread-container-dark:hover .delete-chat-thread-icon-dark {
  display: block;
}

.delete-chat-thread-col:hover .delete-chat-thread-icon-light {
  color: var(--off-black);
}

.delete-chat-thread-col:hover .delete-chat-thread-icon-dark {
  color: var(--divider-line-gray);
}

.chat-thread-container-selected-light {
  background-color: #d8d8d8;
}

.chat-thread-container-selected-light p {
  color: #282828;
}

.chat-thread-container-selected-dark {
  background-color: var(--dark-theme-card-background-hover);
}

.chat-thread-container-selected-light:hover {
  background-color: #333;
  /* border: 1px solid var(--light-gray); */
  border: 1px solid #282828;
}

.chat-thread-container-light:hover {
  background-color: #d8d8d8;
  color: #282828 !important;
  /* border: 1px solid var(--light-gray); */
  border: 1px solid #282828;
}

.chat-thread-container-light:hover p {
  color: #282828 !important;
}

.chat-thread-container-dark:hover {
  background-color: var(--dark-theme-card-background-hover);
  border: 1px solid var(--dark-gray);
}

.chat-config-container {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
}

.chat-config-text-light,
.chat-config-text-dark {
  font-weight: 600;
  font-size: 14px;
  color: #fff;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer;
}

.chat-config-text-dark {
  color: var(--dark-theme-clickable-text-blue);
}

.chat-config-text-light:hover {
  font-weight: 700;
}

.chat-config-text-dark:hover {
  font-weight: 700;
  color: var(--dark-theme-clickable-text-blue-hover);
}

.new-chat-thread-modal-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
}

.new-chat-thread-modal-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin-top: 20px;
}

.new-chat-thread-modal-button-row {
  width: 100%;
  margin-top: 10px;
}

.copy-markdown-icon {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 0px;
  margin-bottom: -40px;
  margin-right: 6px;
  cursor: pointer;
}

.copied-text {
  /* show a transition when this text is displayed */
  font-family: "Inter";
  font-weight: 500;
  font-size: 14px;
  color: white;
  line-height: 22px;
  text-align: right;
  margin-top: 0px;
  margin-bottom: 5px;
  z-index: 10;
}

.sources-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  margin-top: 20px;
}

.source-row {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 20px;
  padding: 0px 15px;
  cursor: pointer;
  background-color: rgba(93, 36, 216, 0.15);
  border-radius: 5px;
  margin-top: 8px;
  margin-bottom: 8px;
  max-width: 275px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.sources-col {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.source-text-light,
.source-text-dark {
  font-family: "Inter";
  font-weight: 600;
  font-size: 14px;
  color: #af98f3;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 8px;
  margin-bottom: 8px;
}

.source-text-dark {
  color: var(--dark-theme-clickable-text-blue);
}

.source-row:hover {
  background-color: rgba(93, 36, 216, 0.3);
}

.chat-result-type-text-light,
.chat-result-type-text-dark {
  font-family: "Inter";
  font-weight: 600;
  font-size: 20px;
  color: var(--medium-gray);
  margin-right: 100px;
  cursor: pointer;
}

.chat-result-type-text-dark {
  color: var(--light-gray);
}

.chat-result-type-text-light-selected,
.chat-result-type-text-dark-selected {
  font-family: "Inter";
  font-weight: 700;
  font-size: 20px;
  color: #fff;
  margin-right: 100px;
  /*text-decoration: underline;*/
  /* make the underline bolder, and have a larger offset */
  /*text-decoration-thickness: 2px;
    text-underline-offset: 5px;*/
}

.chat-result-type-text-dark-selected {
  color: var(--divider-line-gray);
}

.chat-result-type-text-light:hover {
  color: var(--off-black);
}

.chat-result-type-text-dark:hover {
  color: var(--divider-line-gray);
}

.view-queries-and-results-light,
.view-queries-and-results-dark {
  font-family: "Inter";
  font-weight: 600;
  font-size: 14px;
  color: var(--message-blue);
  cursor: pointer;
  margin-top: 6px;
}

.view-queries-and-results-light:hover {
  color: var(--darker-blue);
  font-weight: 700;
}

.view-queries-and-results-dark {
  color: var(--dark-theme-clickable-text-blue);
}

.view-queries-and-results-dark:hover {
  color: var(--dark-theme-clickable-text-blue-hover);
  font-weight: 700;
}

.superpowered-thinking-chat-message {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  height: 30px;
  width: 40px;
  width: 100%;
  max-width: 850px;
  margin: auto;
}

.superpowered-thinking-dots {
  font-family: "Inter";
  font-weight: 500;
  font-size: 34px;
  margin-bottom: 54px;
  color: var(--medium-gray);
}

@media screen and (max-width: 1450px) {
  .chat-main-container {
    flex: 3;
  }

  .chat-message-content-container,
  .chat-message-header-container,
  .superpowered-thinking-chat-message {
    /* 3 to 1 flex ratio, minus the left and right padding on the message container */
    max-width: calc(75vw - 360px);
  }
}

@media screen and (max-width: 1200px) {
  .chat-message-container-light,
  .chat-message-container-dark {
    padding: 25px 40px 25px 40px;
  }

  .chat-message-content-container,
  .chat-message-header-container {
    /* 3 to 1 flex ratio, minus the left and right padding on the message container */
    max-width: calc(75vw - 65px);
  }

  .chat-main-container {
    flex: 3;
  }

  .chat-sidebar-container {
    padding-left: 25px;
  }

  .chat-send-message-container {
    padding: 10px 40px 20px 40px;
  }
}

.welcome-text-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 1000px;
  margin: auto;
}

.welcome-text-wrapper h1 {
  font-size: 2.5rem;
  font-family: Google Sans, Helvetica Neue, sans-serif !important;
  font-weight: 700;
  margin-bottom: 0em;
  background: linear-gradient(to right, #5082ee, #a66fbb, #d76572);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: inline-block;
}

.welcome-text-wrapper h2 {
  font-size: 2.2em;
  font-family: Google Sans, Helvetica Neue, sans-serif !important;
  font-weight: 700;
  margin-bottom: 0.1em;
  background: #fff;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: inline-block;
  opacity: 0.6;
}

.suggestion-wrapper {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  gap: 20px;
  max-width: 860px;
  margin: 20px;
}

.suggestion-chip {
  background-color: #1e1f20;
  border-radius: 10px;
  height: 100%;
  width: 100%;
  max-height: 200px;
  padding: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
}

.suggestion-chip:hover {
  background-color: #323537;
  cursor: pointer;
}

.suggestion-wrapper p {
  color: #fff;
  font-size: 0.9rem;
  font-weight: 400;
  margin: 1rem;
  font-family: Google Sans, Helvetica Neue, sans-serif;
  line-height: 1.375rem;

  @media (max-width: 1300px) {
    font-size: 0.8rem; /* Reduced font size for smaller screens */
  }

  @media (max-width: 1160px) {
    font-size: 0.7rem; /* Reduced font size for smaller screens */
  }
}

.suggestion-icon {
  color: #ffd700;
}

.ai-chat {
  border-radius: 80px;
  height: 40px;
  width: 40px;
}

.user-chat {
  border-radius: 80px;
  height: 40px;
  width: 40px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 16px;
}

.copy-wrapper{
  display: flex;
  flex-direction: row;
}

.secondary-buttons-wrapper{
  display: flex;
  gap: 10px;
}

.archive-section {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
}

.previous-reviews-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 1000px;
    margin: auto;
    max-height: 100vh;
    overflow-y: scroll;
    padding: 24px;
}

.previous-document-review-container {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid var(--light-gray);
    width: 100%;
}

.document-review-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 10px;
    padding: 5px;
    width: 100%;
}

.document-review-date-col {
    display: flex;
    flex: 2;
}

.previous-review-result-data {
    padding-bottom: 20px;
}

.document-review-title-col {
    display: flex;
    flex: 7;
}

.view-review-col {
    display: flex;
    flex: 1;
    justify-content: flex-end;
}

.view-review-text {
    font-weight: 600;
    color: #fff;
    font-size: 14px;
    margin-top: 5px;
    margin-bottom: 5px;
    cursor: pointer;
}

.view-review-text:hover {
    color: #4417c7;
    font-weight: 700;
}

.content-or-review-text-container {
    display: flex;
    flex-direction: row;
}

.content-or-review-text {
    font-weight: 400;
    color: #fff;
    font-size: 18px;
    margin-top: 5px;
    margin-bottom: 5px;
    cursor: pointer;
    margin-right: 30px;
}

.content-or-review-text-selected, .content-or-review-text:hover {
    font-weight: 700;
    color: #4417c7;
    font-size: 18px;
    margin-top: 5px;
    margin-bottom: 5px;
    margin-right: 30px;
}
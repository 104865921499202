.sort-by-header-text-light, .sort-by-header-text-dark {
    font-family: "Inter";
    font-weight: 600;
    font-size: 14px;
    margin-right: 10px;
    color: var(--medium-gray);
    margin-right: 5px;
}

.sort-by-header-text-dark {
    color: var(--light-gray);
}

.sort-by-header-text-selected-light {
    color: #fff;
}

.sort-by-header-text-selected-dark {
    color: var(--divider-line-gray);
}

.sort-arrow-light, .sort-arrow-dark {
    color: var(--medium-gray);
    font-size: 16px;
    font-weight: 400;
    margin-top: 3px;
    margin-left: 5px;
    cursor: pointer;
}

.sort-arrow-selected-light, .sort-arrow-selected-dark {
    color: #fff;
    font-size: 16px;
    font-weight: 700;
    margin-top: 3px;
    margin-left: 5px;
    cursor: pointer;
}

.sort-arrow-selected-dark {
    color: var(--divider-line-gray);
}


/* Hover states */
.sort-arrow-row:hover .sort-by-header-text-light {
    color: var(--off-black);
    font-weight: 700;
}

.sort-arrow-row:hover .sort-by-header-text-dark {
    color: var(--divider-line-gray);
    font-weight: 700;
}

.sort-arrow-row:hover .sort-arrow-light {
    color: var(--off-black);
}

.sort-arrow-row:hover .sort-arrow-dark {
    color: var(--divider-line-gray);
}

.info-box-container-light, .info-box-container-dark {
    position: absolute;
    top: -20px;
    left: 100px;
    width: 400px;
    background-color: #1b1b1b;
    padding: 10px 20px;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    z-index: 10;
}

.info-box-container-dark {
    background-color: var(--dark-theme-card-background);
}

.info-box-header-text {
    margin-bottom: 10px;
}

.document-upload-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 250px;
    margin-bottom: 30px;
    margin-top: 30px;
}

.alternate-upload-method-choice-light, .alternate-upload-method-choice-dark {
    display: flex;
    flex: 1;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: white;
    border: 1px solid var(--divider-line-gray);
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    padding: 5px 30px;
    cursor: pointer;
    margin-top: 5px;
}

.alternate-upload-method-choice-dark {
    background-color: var(--dark-theme-card-background);
    border: none;
}

.alternate-upload-method-choice-light:hover {
    background-color: var(--off-white);
    border: 1px solid var(--divider-line-gray);
}

.alternate-upload-method-choice-dark:hover {
    background-color: var(--dark-theme-card-background-hover);
    border: none;
}

.alternate-upload-method-text-light, .alternate-upload-method-text-dark {
    font-size: 14px;
    font-weight: 500;
    margin-top: 8px;
    margin-bottom: 8px;
    margin-left: 10px;
    margin-right: 10px;
    color: var(--off-black);
}

.alternate-upload-method-text-dark {
    color: var(--divider-line-gray);
}

.file-upload-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    border: 1px solid white;
    border-radius: 5px;
    padding: 10px;
    margin-top: 10px;
}

.valid-file-types-text {
    font-family: "Inter";
    font-size: 12px;
    font-weight: 300;
    margin-top: -15px;
    margin-bottom: 20px;
    margin-left: 0px;
    margin-right: 10px;
    color: #fff;
}
.dropdown {
    position: relative;
    display: inline-block;
    width: 200px;
    margin-left: 20px;
  }
  
  .dropdown-toggle {
    background-color: #131314;
    color: #E1E1E1;
    /* border: 1px solid #ced4da; */
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    position: relative;
    box-shadow: none;
  }
  
  /* .dropdown-toggle:hover {
    background-color: #e9ecef;
  } */
  
  .dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    background-color: #131314;
    border-top: none;
    border-radius: 0 0 5px 5px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    width: 200px;
  }
  
  .dropdown-menu button {
    display: block;
    width: 100%;
    padding: 10px 20px;
    background-color: transparent;
    border: none;
    text-align: left;
    cursor: pointer;
    color: #E1E1E1;
  }
  
  .dropdown-menu button:hover {
    background-color: #272A2C;
  }
  
  .selected-icon {
    float: right;
  }
  
  .dropdown-icon {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
  }
  
  .dropdown-icon.open {
    transform: translateY(-50%) rotate(180deg);
  }
  
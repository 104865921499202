.edit-knowledge-base-info-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1100px;
  margin: auto;
  padding-top: 80px;
  padding-bottom: 80px;
  padding-left: 40px;
  padding-right: 40px;
  height: 100vh;
}

.edit-knowledge-base-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 1000px;
  padding-top: 80px;
  margin: auto;
}

.edit-knowledge-base-header-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 0px;
  width: 100%;
}

.edit-knowledge-base-header-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
}

/*.edit-knowledge-base-title {
    font-family: "Inter";
    font-weight: 700;
    font-size: 16px;
    margin-top: 0px;
    margin-bottom: 5px;
}*/

.edit-knowledge-base-id-light,
.edit-knowledge-base-id-dark {
  font-family: "Inter";
  font-weight: 400;
  font-size: 13px;
  margin-top: 0px;
  margin-bottom: 0px;
  color: var(--medium-gray);
}

.edit-knowledge-base-id-dark {
  color: var(--light-gray);
}

.edit-knowledge-base-back-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
}

.edit-knowledge-base-back-arrow-light,
.edit-knowledge-base-back-arrow-dark {
  color: var(--medium-gray);
  margin-right: 8px;
}

.edit-knowledge-base-back-arrow-dark {
  color: var(--light-gray);
}

.edit-knowledge-base-back-container:hover
  .edit-knowledge-base-back-arrow-light {
  color: var(--off-black);
}

.edit-knowledge-base-back-container:hover .edit-knowledge-base-back-arrow-dark {
  color: var(--divider-line-gray);
}

.edit-knowledge-base-back-container:hover .edit-knowledge-base-back-text-light {
  color: var(--off-black);
}

.edit-knowledge-base-back-container:hover .edit-knowledge-base-back-text-dark {
  color: var(--divider-line-gray);
}

.edit-knowledge-base-back-text-light,
.edit-knowledge-base-back-text-dark {
  font-family: "Inter";
  font-weight: 600;
  font-size: 14px;
  color: var(--medium-gray);
}

.edit-knowledge-base-back-text-dark {
  color: var(--light-gray);
}

.add-files-icon {
  color: white;
  font-size: 16px;
  margin-bottom: -2px;
}

.edit-knowledge-base-title-container {
  display: flex;
  flex: 3;
  flex-direction: row;
  justify-content: flex-start;
}

.edit-knowledge-base-ellipsis-container {
  margin-left: 20px;
  padding: 1px 12px;
  position: relative;
  height: 24px;
}

.edit-knowledge-base-search-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex: 2;
}

.edit-knowledge-base-ellipsis-icon-light,
.edit-knowledge-base-ellipsis-icon-dark {
  color: var(--off-black);
  font-size: 22px;
  margin-top: 2px;
}

.edit-knowledge-base-ellipsis-icon-dark {
  color: var(--divider-line-gray);
}

.edit-knowledge-base-ellipsis-light,
.edit-knowledge-base-ellipsis-dark {
  padding: 0px 10px;
  cursor: pointer;
}

.edit-knowledge-base-ellipsis-light:hover {
  background-color: var(--divider-line-gray);
  border-radius: 5px;
}

.edit-knowledge-base-ellipsis-dark:hover {
  background-color: var(--dark-gray);
  border-radius: 5px;
}

.edit-delete-container-light,
.edit-delete-container-dark {
  position: absolute;
  top: -20px;
  left: 60px;
  width: 230px;
  background-color: white;
  padding: 5px 15px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
}

.edit-delete-container-dark {
  background-color: var(--dark-theme-card-background);
}

.edit-delete-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 0px;
  cursor: pointer;
}

.edit-row:hover .edit-knowledge-base-text {
  font-weight: 700;
}

.delete-row:hover .delete-knowledge-base-text {
  font-weight: 700;
}

.edit-knowledge-base-icon-light,
.edit-knowledge-base-icon-dark {
  color: var(--off-black);
  font-size: 15px;
  margin-right: 15px;
}

.edit-knowledge-base-icon-dark {
  color: var(--divider-line-gray);
}

.delete-knowledge-base-icon {
  color: var(--delete-red);
  font-size: 15px;
  margin-right: 15px;
}

.delete-knowledge-base-text {
  font-family: "Inter";
  font-weight: 500;
  font-size: 14px;
  color: var(--delete-red);
  cursor: pointer;
  margin-top: 10px;
  margin-bottom: 10px;
}

.delete-knowledge-base-text:hover {
  font-weight: 700;
}

.edit-knowledge-base-text-light,
.edit-knowledge-base-text-dark {
  font-family: "Inter";
  font-weight: 500;
  font-size: 14px;
  color: var(--off-black);
  cursor: pointer;
  margin-top: 10px;
  margin-bottom: 10px;
}

.edit-knowledge-base-text-dark {
  color: var(--divider-line-gray);
}

.edit-knowledge-base-text-light:hover,
.edit-knowledge-base-text-dark:hover {
  font-weight: 700;
}

.edit-knowledge-base-add-files-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  flex: 2;
}

.edit-knowledge-base-delete-container {
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: flex-end;
}

.edit-knowledge-base-delete {
  font-family: "Inter";
  font-weight: 600;
  font-size: 15px;
  color: var(--delete-red);
  cursor: pointer;
}

.edit-knowledge-base-info-container {
  width: 100%;
  padding-top: 10px;
  padding-bottom: 50px;
}

.knowledge-base-files-container {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  width: 100%;
}

.knowledge-base-files-container-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.knowledge-base-select-files-text {
  font-family: "Inter";
  font-weight: 500;
  font-size: 15px;
  margin-top: 0px;
  margin-bottom: 0px;
  cursor: pointer;
}

.edit-knowledge-base-filename-text {
  font-family: "Inter";
  font-weight: 600;
  font-size: 15px;
  margin-top: 10px;
  margin-bottom: 10px;
  color: var(--message-blue);
  cursor: pointer;
}

.edit-knowledge-base-filename-text:hover {
  font-weight: 700;
}

.knowledge-base-file-row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}

.knowledge-base-select-delete-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.knowledge-base-delete-files-text {
  font-family: "Inter";
  font-weight: 600;
  font-size: 15px;
  color: var(--delete-red);
  cursor: pointer;
  margin-right: 25px;
  margin-bottom: 5px;
  margin-top: 5px;
  background-color: #1e1f20;
  padding: 10px;
  border-radius: 8px;
}

.knowledge-base-delete-files-text:hover {
  background-color: #323537;
  cursor: pointer;
}

/* Add this to your CSS file or style tag */

.ReactModal__Overlay,
.ReactModal__Content {
  background: transparent !important;
  border: none !important;
}

.ReactModal__Content {
  display: flex;
  justify-content: center;
  align-items: center;
}

.prompts-dialog {
  display: flex;
  background: #1b1b1b; /* Dark background color */
  border-radius: 10px;
  overflow: hidden;
  height: 500px;
  width: 1200px;
  border: 1px solid #6c6c6c;
  flex-direction: column;
  position: relative;
}

.prompts-sidebar {
  flex: 0 0 20%;
  display: flex;
  flex-direction: column;
  padding: 20px;
  box-sizing: border-box;
}

.category-button {
  background: none;
  border: none;
  color: #e7e7e7;
  padding: 10px;
  margin-bottom: 5px;
  text-align: left;
  font-size: 14px;
  cursor: pointer;
  border-radius: 8px;
}

.category-button.selected {
  color: white;
  background: #1b574c;
}
.category-button:hover {
  color: white;
  background: #242424; /* Highlight color for selected/hover */
}

.prompts-main {
  flex: 1;
  padding: 20px;
  display: flex;
  flex-direction: column;
  border-left: 1px solid #242424;
  border-right: 1px solid #242424;
  max-height: 400px;
  overflow-y: scroll;
}

.prompts-list {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow-y: auto;
}

.prompt-button {
  background: none;
  border: none;
  color: #e7e7e7;
  padding: 10px;
  margin-bottom: 5px;
  text-align: left;
  font-size: 14px;
  cursor: pointer;
  border-radius: 8px;
}

.prompt-button.selected {
  color: white;
  background: #1b574c;
}
.prompt-button:hover {
  color: white;
  background: #242424; /* Highlight color for selected/hover */
}

.prompt-preview {
  background: #16213e; /* Slightly lighter dark background for preview */
  padding: 20px;
  border-radius: 10px;
  margin-top: auto;
}

.close-modal-button {
  padding: 8px;
  position: absolute;
  top: 200px;
  background: white;
  border: none;
  color: black;
  cursor: pointer;
  border-radius: 6px;
  font-size: 1rem;
}

/* Add scroll bar styling for prompts-list */
.prompts-list::-webkit-scrollbar {
  width: 5px;
}

.prompts-list::-webkit-scrollbar-thumb {
  background: #242424;
  border-radius: 5px;
}

/* Button to use the selected prompt */
.use-prompt-button {
  background: #21bf73; /* Button color */
  color: white;
  border: none;
  padding: 10px 20px;
  margin-top: 20px;
  cursor: pointer;
  font-size: 1rem;
  border-radius: 5px;
}

.use-prompt-button:hover {
  background: #1a9751; /* Darken button color on hover */
}

.prompts-button {
  background: #1b574c;
  color: #fff;
  font-size: 14px;
  border-radius: 6px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  margin-top: 10px;
  cursor: pointer;
}

.prompts-selected-prompt {
  font-size: 10px;
  text-transform: uppercase;
  color: #b4b4ba;
}

.prompts-selected-title {
  margin-top: 10px;
  font-size: 16px;
  color: #fff;
}

.prompts-selected-wrapper {
  width: 400px;
  height: 350px;
  background: #242424;
  padding: 14px;
  border-radius: 6px;
}

.prompts-header-wrapper {
  font-size: 24.5px;
  margin: 10px;
  padding: 10px;
  color: #fff;
  border-bottom: 1px solid #242424;
}


.clickable-row-section-light, .clickable-row-section-dark {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.1);
    background-color: white;
    border-radius: 5px;
}

.clickable-row-section-dark {
    background-color: var(--dark-theme-card-background);
}

.clickable-row-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 10px 25px 10px 20px;
    width: 100%;
    cursor: pointer;
}

.clickable-row-arrow-col {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.clickable-row-col {
    display: block;
    width: 100%;
    /* Make the text not wrap to a new line, show an ellipsis instead */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 10px;
}

.clickable-row-text {
    /* Make the text not wrap to a new line, show an ellipsis instead */
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.clickable-row-text-checkbox {
    max-width: 220px;
}

.clickable-row-arrow-icon-light {
    font-size: 18px;
    margin-top: 3px;
}

.clickable-row-arrow-icon-dark {
    font-size: 18px;
    margin-top: 3px;
    color: var(--light-gray)
}

.clickable-row-section-light:hover {
    /* Darken the background slightly */
    background-color: var(--divider-line-gray);
}

.clickable-row-section-dark:hover {
    /* Darken the background slightly */
    background-color: var(--dark-theme-card-background-hover)
}


.clickable-row-section-delete-light, .clickable-row-section-delete-dark {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.1);
    background-color: white;
    border-radius: 5px;
}

.clickable-row-section-delete-dark {
    background-color: var(--dark-theme-card-background);
}

.clickable-row-delete-icon-light, .clickable-row-delete-icon-dark {
    font-size: 16px;
    margin-top: 3px;
    cursor: pointer;
    color: var(--medium-gray)
}

.clickable-row-delete-icon-light:hover {
    color: var(--off-black);
}

.clickable-row-delete-icon-dark:hover {
    color: var(--divider-line-gray);
}

.clickable-container-section-light {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.1);
    background-color: white;
    border-radius: 5px;
    cursor: pointer;
}

.clickable-container-section-dark {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.1);
    background-color: var(--dark-theme-card-background);
    border-radius: 5px;
    cursor: pointer;
}

.clickable-container-container {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    padding: 10px 20px;
}

.clickable-container-section-light:hover {
    /* Darken the background slightly */
    background-color: rgba(0, 0, 0, 0.03);
}

.clickable-container-section-dark:hover {
    /* Darken the background slightly */
    background-color: var(--dark-theme-card-background-hover)
}


.clickable-row-checkbox-col {
    display: flex;
    flex: 1;
    margin: 0px 5px;
}


@media screen and (max-width: 1000px) {

    .clickable-row-text-checkbox {
        max-width: 190px;
    }

}

@media screen and (max-width: 850px) {

    .clickable-row-text-checkbox {
        max-width: 175px;
    }

}

.chat-section {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
}

.chat-container {
    display: flex;
    flex-direction: row;
}

.chat-main-container {
    display: flex;
    flex: 5;
    background: rgb(22, 21, 26);
}

.chat-sidebar-container-light {
    display: flex;
    flex: 1;
    padding-right: 20px;
}

.custom-system-message-modal-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
}